import React from 'react';
import { Cropper, FlexGrid, UnsafeHTML, TypographyHero, TypographyStyler, TypographyBody } from '@honeycomb/ui-core';
import { useGlobalContext } from '@honeycomb/data';
import { useRootComponent } from './RootComponentContext';
import { getBackgroundColor } from './utils/getBackgroundColor';
import { getHeadingColor, getBodyColor } from './utils';
export function IntroText({ headingText, bodyText }) {
    const { resourceStrings: { HC_READ_MORE, HC_READ_LESS }, } = useGlobalContext();
    const { backgroundColor } = useRootComponent();
    const bgColor = getBackgroundColor(backgroundColor);
    const headingColor = getHeadingColor(backgroundColor);
    const textColor = getBodyColor(backgroundColor);
    return (React.createElement(FlexGrid, { container: true, columnSpacing: 8 },
        React.createElement(FlexGrid, { l: 6 },
            React.createElement(TypographyHero, { size: "xl", color: headingColor, mb: { xs: 6, l: 0 } }, headingText.toUpperCase())),
        React.createElement(FlexGrid, { l: 6 },
            React.createElement(Cropper, { maxHeight: 108, buttonAlignment: "flex-start", contentBackgroundColor: bgColor !== null && bgColor !== void 0 ? bgColor : 'background.body', ButtonProps: { color: 'text.link', weight: 'semiBold' }, buttonCaption: { cropped: HC_READ_MORE, expanded: HC_READ_LESS } },
                React.createElement(TypographyStyler, { component: "span" },
                    React.createElement(TypographyBody, { color: textColor, component: "span" },
                        React.createElement(UnsafeHTML, null, bodyText)))))));
}

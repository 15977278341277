import { BreakpointKey, ColorValue } from '@honeycomb/ui-core';

export type HeroGradient = 'light' | 'dark' | 'none';

export interface HeroTheme {
    minHeight: { [key in BreakpointKey]?: React.CSSProperties['minHeight'] };
    paddingBottom: { [key in BreakpointKey]?: string };
    contentPaddingY: { [key in BreakpointKey]?: string };
    contentPaddingLeft: { [key in BreakpointKey]?: string };
    gradientColor: ColorValue;
    gradientIntensity: { [key in HeroGradient]: number };
}

export const MIN_HERO_HEIGHT_XS = 360;
export const MIN_HERO_HEIGHT_L = 580;
export const MIN_HERO_HEIGHT_XXL = 680;

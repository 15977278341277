import React from 'react';
import { useRenderCount } from '@honeycomb/data';
import { HoneycombThemeProvider, useHoneycombTheme } from '@honeycomb/ui-core';
import Head from 'next/head';
import { PageContent } from '../features/content/PageContent';
export function CmsContentPage({ getPage: pageContent }) {
    var _a;
    useRenderCount('CmsContentPage');
    const { theme } = useHoneycombTheme();
    const { palette } = theme;
    const noIndex = pageContent.noIndex ? 'noindex' : undefined;
    const noFollow = pageContent.noFollow ? 'nofollow' : undefined;
    const robotsContent = [noIndex, noFollow].filter(Boolean).join(',');
    const robotsMeta = robotsContent && React.createElement("meta", { name: "robots", content: robotsContent });
    // We want to force the new TR rebrand body color for landing pages but leave it white everywhere else.
    return (React.createElement(HoneycombThemeProvider, { theme: { palette: { background: { body: palette.background.offset } } } },
        React.createElement(Head, null, robotsMeta),
        React.createElement(PageContent, { components: (_a = pageContent.components) !== null && _a !== void 0 ? _a : [] })));
}

import { TypographyBody, useBreakpoints, TypographyHero, Stacker, FlexGrid, Image, Button, Paper, Styler, PlayIcon, } from '@honeycomb/ui-core';
import React, { useState } from 'react';
import { getHeadingColor, getBodyColor } from './utils';
import { useRootComponent } from './RootComponentContext';
import { VideoPlayer } from '../video/VideoPlayer/VideoPlayer';
import { VideoModal } from '../video/VideoModal/VideoModal';
export var Alignment;
(function (Alignment) {
    Alignment[Alignment["left"] = 0] = "left";
    Alignment[Alignment["right"] = 1] = "right";
})(Alignment || (Alignment = {}));
export function LandingPageVideo(props) {
    var _a, _b, _c;
    const { videoLink, thumbnailImageUrl, subHeading, subHeadingDescription, button, videoAlignment = Alignment.right, playInPopUp = true, } = props;
    const [open, setOpen] = useState(false);
    const [showVideoPlayer, setShowVideoPlayer] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleClick = () => {
        if (playInPopUp) {
            if (!open) {
                setOpen(true);
            }
        }
        else if (!showVideoPlayer) {
            setShowVideoPlayer(true);
        }
    };
    const { mediumUp } = useBreakpoints();
    const subContent = (_a = subHeading !== null && subHeading !== void 0 ? subHeading : subHeadingDescription) !== null && _a !== void 0 ? _a : button;
    const { backgroundColor } = useRootComponent();
    const buttonColor = (button === null || button === void 0 ? void 0 : button.color) || 'primary';
    return (React.createElement(React.Fragment, null,
        React.createElement(FlexGrid, { flexDirection: videoAlignment === Alignment.right ? 'row-reverse' : 'row', container: true, spacing: mediumUp ? 10 : 8, alignItems: "center", px: { xs: 5, l: 10 } },
            React.createElement(FlexGrid, { alignSelf: "center", xs: 12, m: subContent ? 6 : 12 },
                React.createElement(Paper, { button: true, component: "div", onClick: handleClick, pos: "relative", overflow: "hidden" },
                    React.createElement(React.Fragment, null,
                        !showVideoPlayer && (React.createElement(React.Fragment, null,
                            React.createElement(Image, { src: thumbnailImageUrl, withPlaceholder: true, aspectRatio: "16/9" }),
                            React.createElement(Styler, { pos: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" },
                                React.createElement(PlayIcon, { size: "3xl", color: "common.white" })))),
                        showVideoPlayer && React.createElement(VideoPlayer, { videoUrl: videoLink })))),
            subContent && (React.createElement(FlexGrid, { alignSelf: "center", xs: 12, m: 6 },
                React.createElement(Stacker, { spacing: 6 },
                    React.createElement(Stacker, { spacing: 4, ta: "left" },
                        React.createElement(TypographyHero, { size: "l", color: getHeadingColor(backgroundColor), "data-id": "video-component-content-heading" }, subHeading),
                        subHeadingDescription && (React.createElement(TypographyBody, { size: "m", color: getBodyColor(backgroundColor), "data-id": "video-component-content-description" }, subHeadingDescription))),
                    button && (React.createElement(Button, { "data-id": "video-component-content-button", color: buttonColor, size: { xs: 's', s: 'm', m: 'l', l: 'xl' }, alignSelf: "flex-start", variant: button.variant, href: (_b = button.href) !== null && _b !== void 0 ? _b : '#' }, (_c = button.label) !== null && _c !== void 0 ? _c : '')))))),
        playInPopUp && React.createElement(VideoModal, { open: open, onClose: handleClose, videoSrc: videoLink })));
}

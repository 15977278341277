import { BackgroundPosition } from '@honeycomb/data';
export function getBackgroundImagePosition(position) {
    switch (position) {
        case BackgroundPosition.TopLeft:
            return 'left top';
        case BackgroundPosition.BottomLeft:
            return 'left bottom';
        case BackgroundPosition.TopRight:
            return 'right top';
        case BackgroundPosition.BottomRight:
            return 'right bottom';
        default:
            return '';
    }
}
export function hasTiledBackgroundImage(backgrounds) {
    return backgrounds === null || backgrounds === void 0 ? void 0 : backgrounds.some((background) => {
        return 'position' in background && background.position === BackgroundPosition.Tiled;
    });
}
export function getWidgetBackgroundProps(backgrounds) {
    const backgroundImageUrls = [];
    const backgroundImagePositions = [];
    const backgroundImageRepeat = [];
    backgrounds === null || backgrounds === void 0 ? void 0 : backgrounds.forEach((background) => {
        if (background.__typename === 'BackgroundImage') {
            const { url, position } = background;
            backgroundImageUrls.push(`url(${url})`);
            if (position === BackgroundPosition.Tiled) {
                backgroundImageRepeat.push('repeat');
            }
            else {
                backgroundImageRepeat.push('no-repeat');
            }
            if (position !== BackgroundPosition.Tiled) {
                backgroundImagePositions.push(getBackgroundImagePosition(position));
            }
        }
    });
    // Fixed position BG images are only to be applied at breakpoint "m" and up. (THIS IS A TR CONCERN AND SHOULD BE REFACTORED OUT)
    const tiledBackground = hasTiledBackgroundImage(backgrounds);
    const backgroundRepeat = tiledBackground ? backgroundImageRepeat.join(' ') : { m: backgroundImageRepeat.join(' ') };
    const backgroundImage = tiledBackground ? backgroundImageUrls.join(',') : { m: backgroundImageUrls.join(',') };
    return {
        backgroundImage,
        backgroundRepeat,
        backgroundPosition: { m: backgroundImagePositions.join(',') },
    };
}

import React, { useMemo, useCallback } from 'react';
import { Hero as HeroType, ImageOrigin } from '@honeycomb/data';
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    ButtonColor,
    ColorOption,
    Stacker,
    Styler,
    TypographyBody,
    TypographyHero,
    TypographyPrice,
    useBreakpoints,
} from '@honeycomb/ui-core';
import {
    augmentImageUrl,
    getBackgroundColor,
    HeroContent,
    HeroGradient,
    HeroSearch,
    ImageFormat,
    LandingPageSearch,
} from '@honeycomb/ui';
import { Hero } from 'components/Hero/Hero';
import { MIN_HERO_HEIGHT_L, MIN_HERO_HEIGHT_XS, MIN_HERO_HEIGHT_XXL } from 'components/Hero/Hero.types';

function isLightBackground(backgroundColor: ColorOption) {
    return ['common.white', 'background.offset'].includes(backgroundColor);
}

export type HeroProps = {
    heroData: HeroType;
};

export function LandingPageHero(props: HeroProps): React.JSX.Element {
    const {
        heroData: {
            __typename,
            backgroundColor: backgroundColorProp,
            breadcrumbs,
            buttonHref,
            buttonLabel,
            gradient,
            heading,
            hideMobileImage: hideMobileImageProp,
            imageOrigin,
            imageUrl,
            includeBreadcrumbs,
            includeSearchUnit,
            includeTeaserPrice,
            mobileHeroImage,
            searchUnit,
            subheading,
            teaserPrice,
            teaserPriceSubText,
        },
    } = props;

    const imageSetData = useMemo(() => {
        const type = 'custom';
        const format = imageOrigin === ImageOrigin.Facade || imageUrl.includes('media') ? ImageFormat.Webp : undefined;

        return {
            xxl: augmentImageUrl(
                imageOrigin,
                imageUrl,
                type,
                { breakpoint: 'l', format },
                {
                    width: 1348,
                    height: MIN_HERO_HEIGHT_XXL,
                }
            ),
            l: augmentImageUrl(
                imageOrigin,
                imageUrl,
                type,
                { breakpoint: 'l', format },
                {
                    width: 1240,
                    height: MIN_HERO_HEIGHT_L,
                }
            ),
            m: augmentImageUrl(
                imageOrigin,
                imageUrl,
                type,
                { breakpoint: 'm', format },
                { width: 1024, height: MIN_HERO_HEIGHT_XS }
            ),
            s: augmentImageUrl(
                imageOrigin,
                imageUrl,
                type,
                { breakpoint: 's', format },
                {
                    width: 768,
                    height: MIN_HERO_HEIGHT_XS,
                }
            ),
            xs: augmentImageUrl(
                imageOrigin,
                mobileHeroImage || imageUrl,
                type,
                { breakpoint: 'xs', format },
                {
                    width: 540,
                    height: MIN_HERO_HEIGHT_XS,
                }
            ),
        };
    }, [imageOrigin, imageUrl, mobileHeroImage]);

    const { mediumUp } = useBreakpoints();
    const hideMobileImage = hideMobileImageProp ?? false;
    const hideImage = !mediumUp && hideMobileImage;

    const backgroundColor = (backgroundColorProp && getBackgroundColor(backgroundColorProp.name)) || 'primary.base';
    const lightBackground = hideImage && isLightBackground(backgroundColor);
    const contentColor = lightBackground ? 'text.body' : 'text.inverse';
    const buttonColor: ButtonColor = lightBackground ? 'primary' : 'white';

    const getBreadcrumbs = useCallback(
        (isMobile: boolean) => {
            return includeBreadcrumbs && breadcrumbs && breadcrumbs?.length > 0 ? (
                <Styler
                    mt={{ xs: 5, l: 6, xxl: 12 }}
                    maw="100%"
                    data-id={`section-${__typename}-breadcrumbs-component`}
                >
                    <Breadcrumb contained={isMobile} separator="slash" scroll={isMobile} color={contentColor}>
                        {breadcrumbs.map((item, index) => (
                            <BreadcrumbItem key={`Breadcrumb-${item?.url}`}>
                                <BreadcrumbLink href={item?.url} active={index === breadcrumbs.length - 1} size="xs">
                                    {item?.text}
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                        ))}
                    </Breadcrumb>
                </Styler>
            ) : null;
        },
        [__typename, breadcrumbs, includeBreadcrumbs, contentColor]
    );

    const breadcrumb = getBreadcrumbs(false);
    const mobileBreadcrumb = getBreadcrumbs(true);

    return (
        <Hero
            backgroundColor={backgroundColor}
            imageSet={imageSetData}
            ImageProps={{ disableLazy: true, fetchpriority: 'high' }}
            hideMobileImage={hideMobileImage ?? false}
            gradient={(gradient?.toLocaleLowerCase() || 'none') as HeroGradient}
        >
            <HeroContent c={contentColor}>
                {/* TEXT CONTENT */}
                <Stacker spacing={2}>
                    {heading ? (
                        <TypographyHero component="h1" size="3xl">
                            {heading}
                        </TypographyHero>
                    ) : null}
                    {subheading ? (
                        <TypographyBody component="h2" size="2xl">
                            {subheading}
                        </TypographyBody>
                    ) : null}
                    {includeTeaserPrice && teaserPrice && teaserPriceSubText ? (
                        <Styler data-id={`section-${__typename}-root-component`}>
                            <TypographyBody size="m">{teaserPriceSubText}</TypographyBody>
                            <TypographyPrice component="h2" size="xl" color="inherit">
                                {teaserPrice.formatted}
                            </TypographyPrice>
                        </Styler>
                    ) : null}
                </Stacker>

                {/* BREADCRUMBS (these scroll for small devices) */}
                {mobileBreadcrumb && <Styler display={{ xs: 'block', s: 'none' }}>{mobileBreadcrumb}</Styler>}
                {breadcrumb && <Styler display={{ xs: 'none', s: 'block' }}>{breadcrumb}</Styler>}

                {/* CTA BUTTON LINK */}
                {buttonLabel && buttonHref ? (
                    <Styler mt={5}>
                        <Button color={buttonColor} variant="outlined" size={{ xs: 'm', l: 'l' }} href={buttonHref}>
                            {buttonLabel}
                        </Button>
                    </Styler>
                ) : null}
            </HeroContent>

            {/* SEARCH UNIT */}
            {includeSearchUnit && searchUnit ? (
                <HeroSearch>
                    <LandingPageSearch
                        component={searchUnit}
                        key="SearchUnit"
                        paperProps={{ radius: { xs: 'none', l: 'm' }, shadow: { xs: undefined, l: 'l' } }}
                    />
                </HeroSearch>
            ) : null}
        </Hero>
    );
}

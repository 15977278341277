import React, { useCallback } from 'react';
import { Container, Styler, Tab, Tabs, TabsList } from '@honeycomb/ui-core';
import { router, routingTabsCreatedRouting } from '@honeycomb/routing';
export function RoutingTabset(props) {
    const { tabset, onTabChange, selectedTabId } = props;
    const { tabs, __typename } = tabset;
    const changeRoute = useCallback((tabId) => {
        const selectedTab = tabs === null || tabs === void 0 ? void 0 : tabs.find((e) => {
            return (e === null || e === void 0 ? void 0 : e.tabId) === tabId;
        });
        if (selectedTab) {
            router.push(selectedTab.slug, routingTabsCreatedRouting);
        }
    }, [tabs]);
    const handleChange = useCallback((_event, value) => {
        onTabChange(value);
        changeRoute(value);
    }, [changeRoute, onTabChange]);
    return (React.createElement(Styler, { component: "section", py: 6, "data-id": `section-${__typename}-root-component` },
        React.createElement(Container, null,
            React.createElement(Tabs, { "aria-label": "Content tabs", value: selectedTabId || 1, onChange: handleChange, positioning: "centered", underline: "fullWidth", contained: true, variant: "hero", size: { xs: 's', m: 'm', l: 'l' } },
                React.createElement(TabsList, null, tabs === null || tabs === void 0 ? void 0 : tabs.map((tab, index) => {
                    const tabId = (tab === null || tab === void 0 ? void 0 : tab.tabId) || index;
                    return (React.createElement(Tab, { "data-id": `routing-tab-${tabId}`, value: tabId, key: `default-panel-${tabId}` }, tab === null || tab === void 0 ? void 0 : tab.label));
                }))))));
}

import React, { useEffect, useState } from 'react';
import { parseBoolean, setLastSearch, setRecentSearch, useGlobalContext, useSearch, } from '@honeycomb/data';
import { searchCreatedRouting } from '@honeycomb/routing';
import { pushSearchCriteria } from '@honeycomb/tracking';
import { useRouter } from 'next/router';
import { Search } from '../search/Search';
import { getSearchTabs } from '../search/SearchUtil';
import { updateLandingPageSearch } from '../../utils/recentView';
export function LandingPageSearch(props) {
    var _a;
    const { configSettings: { HcSearchFunnelOptions, ProductCacheEnabled, DefaultSearchDestination, DefaultSearchDuration, DefaultSearchDepartureAirport, HotelHolidayDefaultAdultsPerRoom, }, } = useGlobalContext();
    const { component, paperProps, searchTabs } = props;
    const { searchDestination } = component;
    const [initialSearchState, setSearch] = useSearch([searchCreatedRouting, setLastSearch, setRecentSearch]);
    const [searchState, setSearchState] = useState(initialSearchState);
    const isCacheEnabled = parseBoolean(ProductCacheEnabled);
    const destinationId = (() => {
        var _a;
        const id = isCacheEnabled ? searchDestination === null || searchDestination === void 0 ? void 0 : searchDestination.legacyId : searchDestination === null || searchDestination === void 0 ? void 0 : searchDestination.destinationId;
        return (_a = id === null || id === void 0 ? void 0 : id.toString()) !== null && _a !== void 0 ? _a : '';
    })();
    const { asPath } = useRouter();
    const isDestinationOrEstabPage = !!asPath.includes('/1-');
    useEffect(() => {
        var _a, _b;
        const prepopulatedInput = {
            searchParam: initialSearchState,
            id: destinationId,
            title: (searchDestination === null || searchDestination === void 0 ? void 0 : searchDestination.title) || '',
            legacyType: (_a = searchDestination === null || searchDestination === void 0 ? void 0 : searchDestination.legacyType) !== null && _a !== void 0 ? _a : 0,
            destination: DefaultSearchDestination,
            duration: DefaultSearchDuration,
            airports: DefaultSearchDepartureAirport,
            adults: HotelHolidayDefaultAdultsPerRoom,
            isCacheEnabled,
            isCached: (_b = searchDestination === null || searchDestination === void 0 ? void 0 : searchDestination.isCached) !== null && _b !== void 0 ? _b : false,
            isDestinationOrEstabPage,
        };
        const updatedState = updateLandingPageSearch(prepopulatedInput);
        setSearchState(updatedState);
    }, [
        DefaultSearchDepartureAirport,
        DefaultSearchDestination,
        DefaultSearchDuration,
        HotelHolidayDefaultAdultsPerRoom,
        destinationId,
        initialSearchState,
        isCacheEnabled,
        searchDestination === null || searchDestination === void 0 ? void 0 : searchDestination.isCached,
        searchDestination === null || searchDestination === void 0 ? void 0 : searchDestination.legacyType,
        searchDestination === null || searchDestination === void 0 ? void 0 : searchDestination.title,
        isDestinationOrEstabPage,
    ]);
    let searchFunnel = (_a = searchTabs) !== null && _a !== void 0 ? _a : [];
    // default search type
    if (searchFunnel.length === 0) {
        searchFunnel = getSearchTabs('landingPage', HcSearchFunnelOptions);
    }
    return (React.createElement(Search, { state: searchState, action: setSearch, tracking: pushSearchCriteria, PaperProps: paperProps, searchFunnel: searchFunnel }));
}

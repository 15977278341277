import React from 'react';
import { Container, FlexGrid, Anchor } from '@honeycomb/ui-core';
export function InlineLinkGroup({ linkGroup: { links, contentId } }) {
    return (React.createElement(Container, { component: "section" },
        React.createElement(FlexGrid, { container: true, spacing: 6, justifyContent: "center", flexDirection: "row" }, links.map((link, index) => {
            var _a;
            const key = `${contentId}-${index}`;
            return (React.createElement(FlexGrid, { key: key },
                React.createElement(Anchor, { size: "xs", href: (_a = link.url) !== null && _a !== void 0 ? _a : '#', color: "text.link" }, link.text)));
        }))));
}

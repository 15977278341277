import * as React from 'react';
import {
    ImageSet,
    ImageBaseProps,
    useHoneycombTheme,
    Styler,
    Picture,
    Container,
    alpha,
    ColorValue,
    useBreakpoints,
    Divider,
} from '@honeycomb/ui-core';

import { HeroContent, HeroSearch } from '@honeycomb/ui';
import { HeroGradient, HeroTheme, MIN_HERO_HEIGHT_L, MIN_HERO_HEIGHT_XS, MIN_HERO_HEIGHT_XXL } from './Hero.types';

export const heroTheme: HeroTheme = {
    minHeight: {
        xs: MIN_HERO_HEIGHT_XS,
        l: MIN_HERO_HEIGHT_L,
        xxl: MIN_HERO_HEIGHT_XXL,
    },
    paddingBottom: {
        xs: '0',
        l: '40px',
        xxl: '48px',
    },
    contentPaddingY: {
        xs: '24px',
        m: '40px',
    },
    contentPaddingLeft: {
        l: '52px',
    },
    gradientColor: '#000000',
    gradientIntensity: {
        none: 0,
        light: 0.5,
        dark: 0.7,
    },
};

export type HeroProps = {
    /**
     * The content of the component.
     */
    children: React.ReactNode;

    /**
     * An object which defines an appropriate image source per breakpoint.
     */
    imageSet: ImageSet;

    /**
     * Additional properties passed to the `Picture` component.
     */
    ImageProps?: ImageBaseProps;

    /**
     * The color of the hero background when an image is not being displayed.
     * @default primary.base
     */
    backgroundColor?: ColorValue;

    /**
     * If `true`, the image will not be displayed below the medium breakpoint.
     * @default false
     */
    hideMobileImage?: boolean;

    /**
     * A gradient which will cover the image, reinforcing the visibility of overaid content.
     * @default none
     */
    gradient?: HeroGradient;
};

export function Hero(props: Readonly<HeroProps>): React.JSX.Element {
    const {
        children,
        imageSet,
        ImageProps,
        backgroundColor: backgroundColorProp = 'primary.base',
        hideMobileImage = false,
        gradient = 'none',
    } = props;

    const { largeUp, mediumUp } = useBreakpoints();

    const { theme } = useHoneycombTheme();

    const [gradientColor, backgroundColor] = React.useMemo(() => {
        return [
            alpha(theme.fn.resolveColorValue(heroTheme.gradientColor), heroTheme.gradientIntensity[gradient]),
            theme.fn.resolveColorValue(backgroundColorProp),
        ];
    }, [gradient, backgroundColorProp, theme.fn]);

    let contentChild;
    let searchChild;
    React.Children.forEach(children, (element) => {
        switch (React.isValidElement(element) && element.type) {
            case HeroContent:
                contentChild = element;
                break;
            case HeroSearch:
                searchChild = element;
                break;
            default:
                break;
        }
    });

    const hideImage = !mediumUp && hideMobileImage;

    return (
        <>
            {/* HERO ROOT */}
            <Styler
                data-hero={true}
                pos="relative"
                display="flex"
                flexDirection="column"
                mih={!hideImage ? { ...heroTheme.minHeight } : undefined}
                pb={{ ...heroTheme.paddingBottom }}
                bg="background.offset"
            >
                {!hideImage && (
                    <>
                        {/* IMAGE */}
                        {imageSet && (
                            <Picture
                                imageSet={imageSet}
                                pos="absolute"
                                top={0}
                                left={0}
                                w="100%"
                                h="100%"
                                objectFit="cover"
                                {...ImageProps}
                            />
                        )}
                        {/* IMAGE GRADIENT */}
                        {gradient !== 'none' && (
                            <Styler
                                data-hero-gradient={true}
                                pos="absolute"
                                top={0}
                                left={0}
                                w="100%"
                                h="100%"
                                bg={{
                                    xs: `linear-gradient(0deg, ${gradientColor} 0%, transparent 75%)`,
                                    l: `linear-gradient(270deg, ${gradientColor} 0%, transparent 75%)`,
                                }}
                            />
                        )}
                    </>
                )}

                {/* CONTENT BACKGROUND (shown when image is hidden) */}
                {contentChild && hideImage && (
                    <Styler pos="absolute" top={0} left={0} w="100%" h="100%" bg={backgroundColor} />
                )}

                {/* CONTENT CONTAINER (overlaying the background) */}
                {contentChild && (
                    <Container
                        data-hero-content-container={true}
                        flexGrow={1}
                        display="flex"
                        alignItems={{ xs: hideImage ? 'center' : 'flex-end', l: 'center' }}
                        justifyContent="flex-end"
                    >
                        <Styler
                            py={{ ...heroTheme.contentPaddingY }}
                            pl={{ ...heroTheme.contentPaddingLeft }}
                            w={{ xs: '100%', l: '50%' }}
                        >
                            {contentChild}
                        </Styler>
                    </Container>
                )}

                {/* SEARCH CONTAINER (inside the hero) */}
                {largeUp && searchChild && (
                    <Container
                        data-hero-search-container={true}
                        flexShrink={0}
                        disableContentMargins={{ xs: true, l: false }}
                    >
                        {searchChild}
                    </Container>
                )}
            </Styler>

            <Divider color="border" thickness={1} display={hideImage ? 'block' : 'none'} />

            {/* SEARCH (below the hero) */}
            {!largeUp && (
                <>
                    {searchChild}
                    <Divider color="border" thickness={1} />
                </>
            )}
        </>
    );
}

import React from 'react';
import { Accordion, AccordionHeader, AccordionContent } from '@honeycomb/ui-core';
import { ItemPropType, SchemaLinkType } from '../../utils/enums';
export function AccordionGroup({ accordionGroup }) {
    const { accordions, emitFaqAttributes } = accordionGroup;
    const accordianItemProp = emitFaqAttributes ? ItemPropType.MainEntity : '';
    const accordianParentSchema = emitFaqAttributes ? SchemaLinkType.Faq : '';
    const accordianSchemaLink = emitFaqAttributes ? SchemaLinkType.Question : '';
    const contentSchemaLink = emitFaqAttributes ? SchemaLinkType.Answer : '';
    const contentItemProp = emitFaqAttributes ? ItemPropType.AcceptedAnswer : '';
    const headerItemProp = emitFaqAttributes ? ItemPropType.Name : '';
    const itemScope = !!emitFaqAttributes;
    return (React.createElement("div", { itemType: accordianParentSchema, itemScope: itemScope }, accordions.map(({ heading, content }, index) => (React.createElement(Accordion, { key: `AccordionGroup-${index.toString()}`, itemtype: accordianSchemaLink, itemScope: itemScope, itemprop: accordianItemProp },
        React.createElement(AccordionHeader, { itemprop: headerItemProp }, heading),
        React.createElement(AccordionContent, { itemtype: contentSchemaLink, itemScope: itemScope, itemprop: contentItemProp },
            React.createElement("div", { itemProp: emitFaqAttributes ? 'text' : '' }, content)))))));
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback } from 'react';
import { FlexGrid, TextField, Paper, Styler, TypographyHero, TypographyBody, Button, Anchor } from '@honeycomb/ui-core';
import { useEmailSubscribe, useGlobalContext } from '@honeycomb/data';
import validator from 'validator';
export function EmailSignupForm(props) {
    const { component } = props;
    const { resourceStrings: { NEWSLETTER_SIGNUP_REASON }, } = useGlobalContext();
    const { buttonText, introHeading, introSubheading, confirmationHeading, confirmationSubheading, privacyPolicyLinkPrefixText, privacyPolicyLinkText, privacyPolicyLinkUrl, } = component;
    const [headings, setHeadings] = React.useState({
        heading: introHeading,
        subheading: introSubheading,
    });
    const { resourceStrings: { HC_VALIDATE_EMAIL, HC_EMAIL }, } = useGlobalContext();
    const [email, setEmail] = React.useState('');
    const [error, setError] = React.useState(false);
    const [busy, setBusy] = React.useState(false);
    const [successData, setSuccessData] = React.useState(false);
    const [emailSubscriber] = useEmailSubscribe([(params) => params]);
    const subscribeEmail = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (!emailSubscriber.error && emailSubscriber.loading === false && !successData) {
            // TODO: We did wrong naming for source in schema. It should be 'signUpReason'.
            yield emailSubscriber.emailSubscribe({
                request: {
                    email,
                    source: NEWSLETTER_SIGNUP_REASON,
                },
            });
            setSuccessData(true);
            if (confirmationHeading && confirmationSubheading) {
                setHeadings({
                    heading: confirmationHeading,
                    subheading: confirmationSubheading,
                });
            }
        }
    }), [confirmationHeading, NEWSLETTER_SIGNUP_REASON, confirmationSubheading, email, emailSubscriber, successData]);
    const submit = useCallback((event) => {
        event.preventDefault();
        if (!email || !validator.isEmail(email)) {
            setError(true);
        }
        else {
            setBusy(true);
            subscribeEmail();
        }
    }, [email, subscribeEmail]);
    const labelText = error && email ? HC_VALIDATE_EMAIL : HC_EMAIL;
    const placeHolderText = error && email === '' ? HC_VALIDATE_EMAIL : undefined;
    const onSubmit = useCallback((event) => {
        submit(event);
    }, [submit]);
    const onChange = useCallback((event) => {
        setEmail(event.target.value);
        setError(false);
    }, []);
    return (React.createElement(Paper, { overflow: "hidden", c: "common.white" },
        React.createElement(FlexGrid, { container: true, fullHeight: true },
            React.createElement(FlexGrid, { container: true, xs: 12, l: "equal" },
                React.createElement(Styler, { bg: { xs: 'primary.base', l: 'accent.base' }, p: { xs: 5, l: 10 }, pb: { xs: 0 }, w: "100%", styleId: "email-signup-heading-panel" }, (headings === null || headings === void 0 ? void 0 : headings.heading) && (React.createElement(TypographyHero, { color: "inherit", size: "xl" }, headings.heading)))),
            React.createElement(FlexGrid, { container: true, xs: 12, l: "equal" },
                React.createElement(Styler, { bg: "primary.base", p: { xs: 5, l: 10 }, w: "100%", styleId: "email-signup-form-panel" },
                    (headings === null || headings === void 0 ? void 0 : headings.subheading) && (React.createElement(TypographyBody, { pb: 4, size: "xl" }, headings.subheading)),
                    !successData && (React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: onSubmit },
                        React.createElement(FlexGrid, { container: true },
                            React.createElement(FlexGrid, { xs: 12, l: 8 },
                                React.createElement(TextField, { fullWidth: true, label: labelText, name: "email", id: "email", autoComplete: "email", type: "email", placeholder: placeHolderText || '', required: true, error: error, onChange: onChange })),
                            React.createElement(FlexGrid, { item: true, xs: 12, l: 4, alignSelf: "center" },
                                React.createElement(Styler, { pl: { xs: 0, l: 2 }, pt: { xs: 3, l: 0 } },
                                    React.createElement(Button, { color: "callToAction", size: "l", loading: busy, fullWidth: true, type: "submit" }, buttonText !== null && buttonText !== void 0 ? buttonText : '')))),
                        privacyPolicyLinkUrl && (React.createElement(Styler, { pt: 3, align: "start" },
                            React.createElement(Anchor, { size: "xs", underline: "none", href: privacyPolicyLinkUrl },
                                React.createElement(TypographyBody, null, `${privacyPolicyLinkPrefixText} ${privacyPolicyLinkText}`)))))))))));
}

import React from 'react';
import { TypographyStyler, UnsafeHTML } from '@honeycomb/ui-core';
export function ContentBlock({ contentBlock: { content, centered } }) {
    // `TypographyStyler` is intended to add Honeycomb styling to raw html content based on the active theme.
    // The HTML should be free from any style tags or other styling attributes, as those will take precedence over
    // the automated styling leading and lead to unintended results.
    return (React.createElement(React.Fragment, null, content.map((child, index) => {
        const { __typename } = child;
        switch (__typename) {
            case 'HTMLBlock':
                return (React.createElement(TypographyStyler, { component: "section", ta: centered ? 'center' : undefined, key: index.toString() },
                    React.createElement(UnsafeHTML, null, child.html)));
            default:
                return React.createElement("div", { key: index.toString() }, __typename);
        }
    })));
}

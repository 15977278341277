import React from 'react';
import { useCurrentRoutingTab, } from '@honeycomb/data';
import { LazyLoadComponent } from '@honeycomb/ui-core';
import dynamic from 'next/dynamic';
import { RoutingTabset } from './RoutingTabset';
import { GenericRootComponent } from './GenericRootComponent';
import { LandingPageHero } from './LandingPageHero';
import { getMerchandiseZones } from '../../utils/merchandiseCard';
const ImageGallery = dynamic(() => import('./ImageGallery').then((module) => module.ImageGallery), {
    ssr: false,
});
const GoogleMap = dynamic(() => import('./GoogleMap').then((module) => module.GoogleMap), {
    ssr: false,
});
const ComponentGroup = dynamic(() => import('./ComponentGroup').then((module) => module.ComponentGroup));
/* eslint no-underscore-dangle: ["error", { "allow": ["__typename"] }] */
export function PageContent(props) {
    const { components } = props;
    const [selectedTabId, setSelectedTabId] = React.useState(1);
    const currentTabSlug = useCurrentRoutingTab();
    React.useEffect(() => {
        var _a;
        const routingTabsSet = components === null || components === void 0 ? void 0 : components.find((e) => e.__typename === 'RoutingTabset');
        if (routingTabsSet) {
            const selectedTab = (_a = routingTabsSet.tabs) === null || _a === void 0 ? void 0 : _a.find((e) => (e === null || e === void 0 ? void 0 : e.slug) === currentTabSlug);
            setSelectedTabId((selectedTab === null || selectedTab === void 0 ? void 0 : selectedTab.tabId) || 1);
        }
    }, [currentTabSlug, components]);
    const handleTabChange = (tabId) => {
        setSelectedTabId(tabId);
    };
    const zoneIds = getMerchandiseZones(components);
    return (React.createElement(React.Fragment, null, components.map((component) => {
        var _a;
        if ((component === null || component === void 0 ? void 0 : component.tabId) !== selectedTabId && (component === null || component === void 0 ? void 0 : component.tabId) != null) {
            return null;
        }
        const { __typename } = component;
        const contentId = component.alias === 'offersWidget'
            ? component.themeContentId
            : component.contentId;
        const key = `PageContent-${__typename}-${contentId}`;
        switch (__typename) {
            case 'Hero':
                return React.createElement(LandingPageHero, { heroData: component, key: key });
            case 'Gallery': {
                return (React.createElement(LazyLoadComponent, { intersectionProps: { triggerOnce: true }, child: React.createElement(ImageGallery, { data: component, key: key }) }));
            }
            case 'Map': {
                return (React.createElement(LazyLoadComponent, { intersectionProps: { triggerOnce: true }, child: React.createElement(GoogleMap, { data: component, key: key }) }));
            }
            case 'RoutingTabset': {
                return (React.createElement(RoutingTabset, { tabset: component, key: key, onTabChange: handleTabChange, selectedTabId: selectedTabId }));
            }
            case 'ComponentGroup': {
                return (React.createElement(LazyLoadComponent, { intersectionProps: { triggerOnce: true }, child: React.createElement(ComponentGroup, { componentGroup: component, key: key }) }));
            }
            case 'GenericComponent':
                return (React.createElement(GenericRootComponent, { component: component, key: key, moreLinkText: (_a = component === null || component === void 0 ? void 0 : component.moreLinkText) !== null && _a !== void 0 ? _a : '', zoneIds: zoneIds }));
            default:
                // unsupported component
                return null;
        }
    })));
}
